.wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #0f2b46;
    z-index: 9999;
    top: 0;
    left: 0;
    text-align: center;
    line-height: 50;
    opacity: 0.8;
}

.wrapper-s {
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.8); */
    z-index: 9999;
    top: 0;
    left: 0;
    text-align: center;
    /* line-height: 50; */
    opacity: 0.8;
}